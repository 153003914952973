import { useVariables } from '@conte-ltd/components-theme'
import { css } from '@emotion/react'
import React, { HTMLAttributes } from 'react'
import type { Theme } from '../styles/theme'

export interface SectionProps extends HTMLAttributes<HTMLElement> {}

export function Section(props: SectionProps) {
  const { spacing } = useVariables<Theme>()

  return (
    <section
      css={css`
        position: relative;
        padding: 0 ${spacing.sm};
      `}
      {...props}
    />
  )
}
